<template>
  <div class="home-nav-bar">
    <div class="nav-content">
      <img :src="iconUrl" class="nav-logo" />
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
        :text-color="textColor"
        active-text-color="#0075FF"
        background-color="transparent"
        @select="handleMenu"
        :default-active="activeMenu"
      >
        <template v-for="(item, index) in menu">
          <el-submenu v-if="item.children" :index="item.path" :key="item.title">
            <template slot="title">{{ item.title }}</template>
            <el-menu-item
              v-for="child in item.children"
              :key="child.title"
              :index="child.path"
              >{{ child.title }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item v-else :index="item.path" :key="index">{{
            item.title
          }}</el-menu-item>
        </template>
      </el-menu>
    </div>
  </div>
</template>
<script>
import { routes } from "@/router";
export default {
  props:{
    textColor:{
      default:"#FFFFFF"
    },
    iconUrl:{
      default:require('@/assets/img/home/logo-1.png')
    }
  },
  data() {
    return {
      menu: [],
      activeMenu: "",
    };
  },
  methods: {
    handleMenu(key) {
      this.$router.push(key);
    },
  },
  created() {
    this.menu = routes.filter((item) => !item.hidden);
    this.activeMenu = this.$route.fullPath;
    if(/^\/join-us/.test(this.$route.path)){
      this.activeMenu = '/join-us';
    }
    if(/^\/product/.test(this.$route.path)){
      this.activeMenu = '/home#product';
    }
  },
  watch: {
    $route(to) {
      this.activeMenu = to.fullPath;
    },
  },
};
</script>
<style scoped lang="scss">
// @media screen and (max-width: 1200px) {
//   .home-nav-bar .nav-content {
//     width: 100%;
//     min-width: none;
//   }
// }
// @media screen and (min-width: 1200px) {
//   .home-nav-bar .nav-content {
//     width: 62.5%;
//     min-width: 1200px;
//   }
// }
.home-nav-bar {
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0px;
  z-index: 999;
  border-bottom: 1px solid rgba(205, 205, 205, 0.20);
  .nav-content {
    width: 1200px;
    height: 100%;
    margin: 0px auto;
    position: relative;
    .nav-logo {
      width: 211px;
      height: 63px;
      position: absolute;
      left: 0px;
    }
    .el-menu-demo {
      position: absolute;
      right: 0px;
    }
    .el-menu-item {
      font-size: 16px;
      padding: 0px 36px;
      &:hover {
        background-color: transparent !important;
        color: #0075ff !important;
      }
    }
    .el-menu.el-menu--horizontal {
      border-bottom: none !important;
    }
  }
}
</style>
