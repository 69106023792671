export const scrollMixin = {
  data() {
    return {
      navBarStyle: "1",
    };
  },

  methods: {
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      if (scrollTop == 0) {
        this.navBarStyle = "1";
      } else {
        this.navBarStyle = "2";
      }
    },
  },
  mounted() {
    //监听页面滚动事件
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    removeEventListener("scroll", this.handleScroll);
  },
};
